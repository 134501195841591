<style lang="scss"></style>

<template>
  <MediaLib library />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import MediaLib from '../../components/Backend/MediaLib/MediaLib.vue';

export default defineComponent({
  components: {
    MediaLib,
  },
  setup() {
    const confirmed1 = ref();
    const confirmed2 = ref();
    const confirm1 = (val: any) => {
      confirmed1.value = val;
    };
    const confirm2 = (val: any) => {
      confirmed2.value = val;
    };
    return {
      confirm1,
      confirm2,
      confirmed1,
      confirmed2,
    };
  },
});
</script>
